.pictures-list {
  @include media("mobile") {
    padding-bottom: rem(100);
  }

  &__header {
    @include media("desktop") {
      padding-top: rem(100);
    }

    @include media("mobile") {
      padding-top: rem(80);
    }
  }

  &__header-title {
    @include media("desktop") {
      width: rem(560);
    }
  }

  &__items {
    @include media("desktop") {
      @include cells-list(4);
      padding-bottom: rem(100);
    }
  }

  &__item {
    @include media("desktop") {
      @include cells-item(4);
      min-height: rem(472);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      &:after {
        top: rem(-36);
        bottom: auto;
        margin-top: -1px;
      }
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }
}
