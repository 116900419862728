.about {
  &__main {
    @include media("mobile") {
      display: flex;
      flex-direction: column;
    }
  }

  &__aside-logo {
    color: var(--color-border);

    @include media("desktop") {
      position: sticky;
      top: rem(20);
      margin-bottom: rem(90);
    }
  }

  &__header {
    @include media("desktop") {
      margin-bottom: rem(120);
    }

    @include media("mobile") {
      order: 2;
    }
  }

  &__description {
    @include media("desktop") {
      margin-bottom: rem(64);
    }

    @include media("mobile") {
      margin-bottom: rem(40);
      order: 3;
    }

    p {
      @include h2-desktop;
      @include h3-mobile;
    }
  }

  &__cover {
    @include media("desktop") {
      margin-bottom: rem(21);
    }

    @include media("mobile") {
      margin-bottom: rem(80);
      order: 1;
      margin-left: rem(-16);
      margin-right: rem(-16);
    }
  }

  &__timeline {
    @include media("desktop") {
      padding-bottom: rem(70);
    }

    @include media("mobile") {
      order: 4;
      padding-bottom: rem(40);
    }
  }

  &__timeline-item {
    border-top: 1px solid var(--color-border);

    @include media("desktop") {
      padding-top: rem(20);
      padding-bottom: rem(20);
      margin-left: rem(-20);
      padding-left: rem(20);
    }

    @include media("mobile") {
      padding-top: rem(16);
      padding-bottom: rem(16);
    }
  }

  &__timeline-item-date {
    @include h2;

    @include media("desktop") {
      margin-bottom: rem(32);
    }

    @include media("mobile") {
      margin-bottom: rem(16);
    }
  }

  &__timeline-item-description {
    @include media("desktop") {
      margin-left: rem(276);
    }

    @include media("mobile") {
      // margin-left: rem(86);
    }
  }

  &__timeline-logo {
    @include media("mobile") {
      margin-left: rem(86);
      margin-top: rem(32);
      margin-bottom: rem(40);
      color: var(--color-border);
    }
  }
}
