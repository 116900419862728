.inset-1 {
  position: relative;

  @include media("desktop") {
    margin-top: rem(84);
    margin-bottom: rem(52);
    padding-top: rem(67);
    padding-bottom: rem(64);
  }

  @include media("mobile") {
    margin-top: rem(60);
    margin-bottom: rem(40);
    padding-top: rem(40);
  }

  &__container {
    @include media("desktop") {
      position: relative;
      width: rem(880);
      padding-right: rem(400);
    }
  }

  &__img {
    @include media("desktop") {
      position: absolute;
      right: 0;
      bottom: rem(-64);
      width: rem(353);
    }

    @include media("mobile") {
      margin-left: auto;
      margin-right: auto;
      margin-top: rem(34);
      width: rem(307);
    }
  }

  &__btn {
    @include media("desktop") {
      margin-top: rem(56);
    }

    @include media("mobile") {
      margin-top: rem(40);
    }
  }

  &__figure {
    @include media("desktop") {
      color: var(--color-border);
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      margin: auto;
      height: rem(248);
      width: calc(50vw - 30.625rem);

      svg {
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
      }
    }

    @include media("mobile") {
      display: none;
    }

    &--left {
      left: 0;

      svg {
        right: 0;
      }
    }

    &--right {
      right: 0;

      svg {
        left: 0;
      }
    }
  }
}
