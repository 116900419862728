.breadcrumbs-float {
  @include media("desktop") {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: rem(20);
    padding-bottom: rem(20);
    background-color: var(--color-bg);
    border-bottom: 1px solid var(--color-border);

    visibility: hidden;
    transform: translateY(0%);
    transition: 0.4s ease-out;
    opacity: 0;
    transition-property: opacity, transform, visibility;

    .body--article-nav-mobile-visible & {
      opacity: 1;
      visibility: visible;
    }

    .body--scroll-up.body--header-small & {
      transform: translateY(100%);
    }
  }

  @include media("mobile") {
    display: none;
  }
}
