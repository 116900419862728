.events-list {
  @include media("desktop") {
    margin-bottom: rem(48);
  }

  @include media("mobile") {
    padding-bottom: rem(24);
    margin-bottom: rem(48);
  }

  &__header {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
      padding-top: rem(28);
      padding-bottom: rem(20);
      margin-bottom: rem(36);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media("mobile") {
      padding-top: rem(20);
      padding-bottom: rem(20);
    }
  }

  &__title {
    margin: 0;
    @include text-button;
  }

  &__btn {
    @include media("desktop") {
      margin-top: rem(36);
    }

    @include media("mobile") {
      margin-top: rem(16);
    }
  }

  &__list {
    @include media("desktop") {
      margin-left: rem(-20);
      margin-right: rem(-20);
      display: flex;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  &__item {
    @include media("desktop") {
      flex: 1 1 50%;
      padding-left: rem(20);
      padding-right: rem(20);
      min-height: rem(184);
      border-left: 1px solid var(--color-border);

      &:first-child {
        border-left: none;
      }
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }
}
