*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

html {
  font-size: 16px;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.25vw;
  }

  @media screen and (max-width: 1023px) {
    font-size: 4.444444vw;
  }
}

body {
  position: relative;
  font-family: $font-text;
  color: var(--color-text);
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: $color-white;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media("desktop") {
    &:not(.header-always) {
      padding-top: rem(102);
    }
  }

  @include media("mobile") {
    padding-top: rem(44);
  }
}

body.body {
  pointer-events: none;
  transition: opacity 0.4s ease-out;

  &--visible {
    pointer-events: initial;
    opacity: 1;
  }
}

p {
  @include text-18;
  margin: 0 0 1em 0;
  display: block;

  &:last-of-type:last-child {
    margin-bottom: 0;
  }
}

b,
strong {
  font-weight: 500;
}

select,
input,
button,
textarea {
  border-radius: 0;
  box-shadow: none;
  outline: none;
  font-family: inherit;
}

button {
  color: inherit;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  display: block;
  width: 100%;
}

input::-ms-clear {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

li,
ol,
ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul,
ol {
  @include text-18;

  @include media("desktop") {
    margin-bottom: rem(16);
  }

  @include media("mobile") {
    margin-bottom: rem(24);
  }

  li {
    position: relative;
    margin: 0;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media("desktop") {
      padding-left: rem(28);
      margin-bottom: rem(8);
    }

    @include media("mobile") {
      padding-left: rem(32);
      margin-bottom: rem(12);
    }
  }
}

ol {
  counter-reset: ol;

  > li {
    &:before {
      counter-increment: ol;
      content: counter(ol) ".";
    }
  }
}

ul {
  > li {
    &:before {
      content: "—";
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
.h1 {
  @include h1;

  @include media("desktop") {
    margin-bottom: rem(18);
  }

  @include media("mobile") {
    margin-bottom: rem(24);
  }
}

h2,
.h2 {
  @include h2;

  @include media("desktop") {
    margin-bottom: rem(32);
  }

  @include media("mobile") {
    margin-bottom: rem(24);
  }
}

h3,
.h3 {
  @include h3;
  margin-top: rem(40);
  margin-bottom: rem(16);
}

h4,
.h4 {
  @include text-button;
  color: var(--color-text-light);

  @include media("desktop") {
    margin-top: rem(36);
    margin-bottom: rem(16);
  }

  @include media("mobile") {
    margin-top: rem(24);
    margin-bottom: rem(8);
  }
}

.button-title {
  @include text-button;
  color: var(--color-text-light);

  @include media("desktop") {
    margin-bottom: rem(8);
  }

  @include media("mobile") {
    margin-bottom: rem(4);
  }
}

.text-button {
  @include text-button;
  color: var(--color-text-light);
}

img {
  display: block;
  width: 100%;
}

iframe {
  display: block;
  width: 100%;
  height: auto;
  border: none;
}

svg {
  display: block;
  width: 100%;
  height: auto;
}

.main {
  position: relative;
  z-index: 2;
  margin-left: rem(388);
  overflow: hidden;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

.container {
  position: relative;
  margin: 0 auto;

  @include media("desktop") {
    max-width: 99%;
    width: rem(1240);
  }

  @include media("mobile") {
    margin-left: rem(16);
    margin-right: rem(16);
  }
}

.mt-36 {
  @include media("desktop") {
    margin-top: rem(36);
  }

  @include media("mobile") {
    margin-top: rem(24);
  }
}

.accent-link {
  color: $color-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  text-decoration: none;
  color: var(--color-text);
  transition: color 0.4s ease-out;

  &:hover {
    color: var(--color-text-light);
  }
}

.link-light {
  text-decoration: none;
  color: var(--color-text-light);
  transition: color 0.4s ease-out;

  &:hover {
    color: var(--color-text);
  }
}

.link-arrow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  @include media("desktop") {
    @include text-18;
  }

  @include media("mobile") {
    @include text-14-16;
  }

  svg {
    @include media("desktop") {
      height: rem(24);
      width: rem(24);
      margin-left: rem(4);
    }

    @include media("mobile") {
      height: rem(18);
      width: rem(18);
      margin-left: rem(6);
    }
  }

  &:hover {
    opacity: 0.5;
  }
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.hidden-mobile {
  @include media("mobile") {
    display: none !important;
  }
}

.hidden-desktop {
  @include media("desktop") {
    display: none !important;
  }
}

.theme-pane {
  overflow: hidden;
}

.theme {
  background-color: var(--color-bg);
  color: var(--color-text);
  border-color: var(--color-border);
}

.theme-base {
  @include theme-base;
  @extend .theme;
}

.theme-creme {
  @include theme-creme;
  @extend .theme;
}

.theme-blue {
  @include theme-blue;
  @extend .theme;
}

.theme-push {
  @include theme-push;
  @extend .theme;
}

.text {
  color: var(--color-text);
}

.text-light {
  color: var(--color-text-light);
}

.bg {
  background-color: var(--color-bg);
}

.border {
  border-color: var(--color-border);
}

.button {
  @include button;
}

.slider-pagination {
  @include text-16;
  color: var(--color-text-light);
}

.slider-nav {
  display: flex;
  gap: rem(16);
}

.slider-prev,
.slider-next {
  width: rem(40);
  color: var(--color-text);

  &[disabled] {
    color: var(--color-text-light);
  }
}

figure {
  margin: 0;

  @include media("desktop") {
    margin-top: rem(40);
    margin-bottom: rem(48);
  }

  @include media("mobile") {
    margin-top: rem(48);
    margin-bottom: rem(40);
  }

  figcaption {
    @include text-14-16;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(16);
    }

    @include media("mobile") {
      margin-top: rem(20);
    }
  }

  img {
    object-fit: cover;

    @include media("desktop") {
      height: rem(400);
    }

    @include media("mobile") {
      height: rem(224);
    }
  }
}

.figure-inset {
  @include media("desktop") {
    margin-top: rem(72);
    margin-bottom: rem(72);
  }

  @include media("mobile") {
    margin-top: rem(48);
    margin-bottom: rem(40);
  }

  img {
    @include media("desktop") {
      height: rem(480);
    }

    @include media("mobile") {
      height: rem(224);
    }
  }
}

blockquote {
  @include text-18;
  margin: rem(32) 0;

  @include media("desktop") {
    border-left: 4px solid var(--color-text-light);
    padding-left: rem(20);
  }

  @include media("mobile") {
    border-left: 2px solid var(--color-text-light);
    padding-left: rem(16);
  }
}
