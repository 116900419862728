.logos {
  &__items {
    display: flex;

    @include media("desktop") {
      padding: rem(20);
      gap: rem(20);
    }

    @include media("mobile") {
      padding: rem(16);
      gap: rem(16);
      justify-content: stretch;
    }
  }

  &__item {
    display: flex;

    @include media("desktop") {
      width: 100%;
      min-height: rem(184);
      padding: rem(20);
    }

    @include media("mobile") {
      width: auto;
      min-height: rem(168);
      padding: rem(16);
      flex: 1 1 auto;
    }

    svg {
      margin: auto;
      height: auto;
      width: auto;

      @include media("desktop") {
        max-width: rem(196);
        max-height: rem(96);
      }

      @include media("mobile") {
        height: rem(54);
      }
    }
  }

  &__items,
  &__item {
    position: relative;
    z-index: 1;
  }

  &__item::before,
  &__items::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__items::before {
    background-color: var(--color-border);
  }

  &__item::before {
    background-color: var(--color-text-light);
    opacity: 0.2;
  }

  &__btn {
  }
}
