.error-page {
  text-align: center;

  @include media("desktop") {
    padding-top: rem(64);
    padding-bottom: rem(100);
  }

  @include media("mobile") {
    padding-top: rem(80);
    padding-bottom: rem(100);
  }

  &__container {
  }

  &__pane {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    position: relative;
    z-index: 1;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-text-light);

    @include media("desktop") {
      width: rem(384);
      height: rem(298);
    }

    @include media("mobile") {
      width: rem(260);
      height: rem(203);
    }

    img {
      position: absolute;
      z-index: -1;
      pointer-events: none;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
    }
  }

  &__code {
    font-weight: 500;
    margin-bottom: rem(8);

    @include media("desktop") {
      font-size: rem(92);
      line-height: rem(80);
    }

    @include media("mobile") {
      font-size: rem(72);
      line-height: rem(60);
    }
  }

  &__text {
    @include text-18;

    @include media("desktop") {
      width: rem(228);
    }

    @include media("mobile") {
      width: rem(162);
    }
  }

  &__link {
    @include text-18;
    display: flex;
    margin-top: rem(24);

    transition: opacity 0.4s ease-out;

    &:hover {
      opacity: 0.5;
    }

    svg {
      width: rem(24);
      margin-left: rem(4);
    }
  }
}
