.links {
  @include media("desktop") {
    margin-top: rem(64);
  }

  @include media("mobile") {
    margin-top: rem(56);
  }

  &__title {
    @include text-16;
    color: var(--color-text-light);
    text-transform: uppercase;
    margin-bottom: rem(20);

    @include media("desktop") {
      font-weight: 500;
    }

    @include media("mobile") {
      font-weight: 500;
    }
  }

  &__list {
    @include media("desktop") {
      display: flex;
      justify-content: space-between;
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
    }
  }

  &__link {
    position: relative;
    @include text-button;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.4s ease-out;

    &:hover {
      color: var(--color-text-light);
    }

    @include media("desktop") {
      padding-top: rem(19);
      padding-bottom: rem(19);
      width: 50%;
      border-top: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);

      &:nth-child(even) {
        margin-left: 20px;
      }

      &:nth-child(odd) {
        margin-right: 20px;
      }

      &:nth-child(even):before {
        content: "";
        position: absolute;
        pointer-events: none;
        top: 0;
        left: rem(-19);
        bottom: 0;
        border-left: 1px solid var(--color-border);
      }
    }

    @include media("mobile") {
      border-bottom: 1px solid var(--color-border);
      padding-top: rem(11);
      padding-bottom: rem(11);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }
  }
}
