.events {
  &__header {
    @include media("desktop") {
      position: relative;
      z-index: 2;
      margin-top: rem(100);
    }

    @include media("mobile") {
      margin-top: rem(80);
    }
  }

  &__type {
    margin-bottom: -1px;

    @include media("desktop") {
      width: rem(470);
      margin-left: auto;
    }
  }

  &__title {
    margin-bottom: 0;

    @include media("desktop") {
      padding-bottom: rem(19);
    }
  }

  &__section-title {
    @include text-button;

    @include media("desktop") {
      margin-top: rem(64);
      margin-bottom: rem(20);
    }

    @include media("mobile") {
      margin-top: rem(74);
      margin-bottom: rem(20);
    }
  }

  &__section {
    @include media("desktop") {
      display: flex;
      flex-wrap: wrap;
      margin-left: rem(-20);
      margin-right: rem(-20);
    }
  }

  &__section-item {
    @include media("desktop") {
      position: relative;
      width: 50%;
      padding: 0 rem(20);
      margin-top: rem(38);
      margin-bottom: rem(38);

      &:before,
      &:after {
        pointer-events: none;
      }

      &:before {
        content: "";
        opacity: 1 !important;
        position: absolute;
        top: rem(-38);
        left: 0;
        right: 0;
        border-top: 1px solid var(--color-border);
      }

      &:nth-child(even):before {
        right: rem(20);
        opacity: 0 !important;
      }

      &:nth-child(odd):before {
        left: rem(20);
        transform: scaleX(2);
        transform-origin: 0% 0%;
      }

      &:nth-child(odd) {
        &:after {
          content: "";
          position: absolute;
          top: rem(0);
          bottom: rem(0);
          right: 0;
          border-left: 1px solid var(--color-border);
        }
      }
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }

  &__list {
    &__empty {
      width: 100%;
      padding: rem(72) rem(24) rem(24) rem(24);
      text-align: center;
      color: var(--color-text-light);
    }
  }
}
