.news {
  &__header {
    @include media("desktop") {
      position: relative;
      z-index: 2;
      padding-top: rem(32);
    }

    @include media("mobile") {
      padding-top: rem(12);
    }
  }

  &__type {
    margin-bottom: -1px;

    @include media("desktop") {
      width: rem(470);
      margin-left: auto;
    }
  }

  &__header-title {
    margin-bottom: 0;
  }

  &__list {
    @include media("desktop") {
      @include cells-list(3);
    }

    &__empty {
      width: 100%;
      padding: rem(72) rem(24) rem(24) rem(24);
      text-align: center;
      color: var(--color-text-light);
    }
  }

  &__list-item {
    @include media("desktop") {
      @include cells-item(3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }

  &__more-btn {
    @include media("desktop") {
      margin-top: -1px;
    }

    @include media("mobile") {
      margin-top: rem(16);
    }
  }
}
