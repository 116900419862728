.tip {
  position: relative;
  align-items: center;
  justify-content: space-between;

  @include media("desktop") {
    display: inline-flex;
  }

  @include media("mobile") {
    display: flex;
    justify-content: flex-start;
  }

  &__icon {
    margin-left: rem(8);

    @include media("desktop") {
      position: relative;
    }
  }

  &__show {
    position: relative;
    color: var(--color-text-light);

    svg {
      cursor: pointer;
      height: rem(24);
      width: rem(24);
    }

    @include media("mobile") {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: rem(-16);
        transform: translateX(-50%);
        border-top: rem(12) solid var(--color-border);
        border-left: rem(12) solid transparent;
        border-right: rem(12) solid transparent;
        border-bottom: rem(12) solid transparent;

        opacity: 0;
        visibility: hidden;
        transition: 0.4s ease-out;
        transition-property: opacity, visibility;
      }
    }
  }

  &__show:hover ~ &__cloud {
    @include media("desktop") {
      opacity: 1;
      visibility: visible;
    }
  }

  &__cloud {
    background-color: var(--color-border);
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1;

    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-out;
    transition-property: opacity, visibility;

    @include media("desktop") {
      @include text-16-desktop;
      padding: rem(20);
      width: rem(340);
      margin-left: rem(12);
      margin-bottom: rem(20);

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        border-top: rem(8) solid var(--color-border);
        border-left: rem(8) solid var(--color-border);
        border-right: rem(8) solid transparent;
        border-bottom: rem(8) solid transparent;
      }
    }

    @include media("mobile") {
      @include text-14-16;
      padding: rem(16) rem(40) rem(20) rem(16);
      width: 100%;
      margin-bottom: rem(12);
    }
  }

  &.active &__show:after,
  &.active &__cloud {
    @include media("mobile") {
      opacity: 1;
      visibility: visible;
    }
  }

  .tickets__item:last-child &__cloud,
  .tickets__item:nth-child(n + 3) &__cloud,
  &__cloud--to-left {
    @include media("desktop") {
      left: auto;
      right: 0;
      margin-right: rem(12);

      &:after {
        left: auto;
        right: 0;
        border-right: rem(8) solid var(--color-border);
        border-left: rem(8) solid transparent;
      }
    }
  }

  &__cloud-close {
    display: none;

    @include media("mobile") {
      display: block;
      position: absolute;
      top: rem(10);
      right: rem(10);
      height: rem(20);
      width: rem(20);
    }
  }
}
