$font-text: "Formular", sans-serif;

//common colors
$color-black: #000000;
$color-white: #ffffff;
$color-text: #333333;
$color-link: #7393bb;

$color-base-bg: #f4efe5;
$color-base-text: #4e4a45;
$color-base-text-light: #908671;
$color-base-border: #e0d7c3;
$color-base-accent: #6e433f;

$color-creme-bg: #d3b39b;
$color-creme-text: #6e433f;
$color-creme-text-light: #8b6859;
$color-creme-border: #b99984;
$color-creme-accent: #6e433f;

$color-blue-bg: #4f5d6d;
$color-blue-text: #f4efe5;
$color-blue-text-light: #a4b1c1;
$color-blue-border: #3b4857;
$color-blue-accent: #f4efe5;

$color-push-bg: #25282a;
$color-push-text: #f4efe5;
$color-push-text-light: #838588;
$color-push-border: #383a3c;
$color-push-accent: #f4efe5;

//media
$break-mobile-small: 359px;
$break-mobile: 767px;
$break-tablet: 1023px;
$break-desktop-small: 1439px;
$break-desktop-medium: 1919px;
$break-desktop-oversize: 2560px;

$transition-page: 0.4s ease-out;
