.grid {
  @include media("desktop") {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__section {
    @include media("desktop") {
      margin-top: rem(64);
      margin-bottom: rem(64);
    }

    @include media("mobile") {
      margin-top: rem(40);
      margin-bottom: rem(40);
    }

    &--margin-small {
      @include media("desktop") {
        margin-top: rem(36);
        margin-bottom: rem(48);
      }

      @include media("mobile") {
        margin-top: rem(24);
        margin-bottom: rem(40);
      }
    }
  }

  &__aside {
    @include media("desktop") {
      margin-right: auto;
      width: rem(492);
      padding-right: rem(20);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__main {
    @include media("desktop") {
      margin-left: auto;
      width: rem(749);
      padding-left: rem(20);
    }
  }

  &--one &__aside {
    @include media("desktop") {
      display: none;
    }
  }

  &--one &__main {
    @include media("desktop") {
      width: rem(728);
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
    }
  }

  &--aside-small &__aside {
    @include media("desktop") {
      width: rem(406);
    }
  }

  &--aside-small &__main {
    @include media("desktop") {
      width: rem(833);
    }
  }

  &--items-end {
    @include media("desktop") {
      align-items: flex-end;
    }
  }

  &--items-center {
    @include media("desktop") {
      align-items: center;
    }
  }

  &--border-desktop &__main {
    @include media("desktop") {
      border-left: 1px solid var(--color-border);
    }
  }

  &--border-mobile &__aside {
    @include media("mobile") {
      padding-bottom: rem(16);
      border-bottom: 1px solid var(--color-border);
      margin-bottom: rem(40s);
    }
  }

  &--half &__aside {
    @include media("desktop") {
      width: 50%;
    }
  }

  &--half &__main {
    @include media("desktop") {
      width: 50%;
    }
  }

  &--items-stretch &__aside {
    @include media("desktop") {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  &--items-stretch &__main {
    @include media("desktop") {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  &__to-top {
    position: sticky;
    top: 90vh;
    top: calc(100vh - 4.6428rem);
    left: 0;
    border-top: 1px solid var(--color-border);
    padding-top: rem(20);
    margin-top: 50vh;

    @include media("mobile") {
      display: none !important;
    }

    a {
      @include text-button;
      display: inline-flex;
      align-items: center;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: var(--color-text-light);
      }
    }

    svg {
      width: rem(24);
      margin-left: rem(4);
    }
  }
}
