.bottom-pane {
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: auto;

  @include media('desktop') {
    max-height: 100vh;
  }

  @include media('mobile') {
    max-height: 100vh;
    max-height: calc(100vh - 2.75rem);
  }
}
