.service-msg {
  border-top: 1px solid var(--color-border);

  @include media("desktop") {
    padding-top: rem(20);
    padding-bottom: rem(20);
  }

  @include media("mobile") {
    padding-top: rem(16);
    padding-bottom: rem(16);
  }

  &__container {
    position: relative;

    @include media("desktop") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    @include text-button;
    @include media("desktop") {
      flex: 0 0 auto;
      width: rem(280);
      margin-right: rem(40);
    }

    @include media("mobile") {
      margin-bottom: rem(8);
      margin-right: rem(64);
    }
  }

  &__text {
    @include media("desktop") {
      @include text-18;
      padding-right: rem(124);
    }

    @include media("mobile") {
      @include text-14-16;
      line-height: rem(17);
      margin-right: rem(64);
    }

    a {
      color: $color-white;
    }

    .link-arrow {
      @include media("mobile") {
        display: flex;
        justify-content: flex-start;
        margin-top: rem(8);
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    height: rem(24);
    width: rem(24);
  }
}
