.tickets {
  @include media("desktop") {
    margin-bottom: rem(36);

    & + & {
      border-top: 1px solid var(--color-border);
      padding-top: rem(36);
    }
  }

  @include media("mobile") {
    margin-bottom: rem(40);

    & + & {
      border-top: 1px solid var(--color-border);
      padding-top: rem(24);
    }
  }

  &--top-border {
    @include media('desktop') {
      border-top: 1px solid var(--color-border);
      padding-top: rem(36);
    }

    @include media('mobile') {
      border-top: 1px solid var(--color-border);
      padding-top: rem(24);
    }
  }

  &__title {
    @include text-button;
    color: var(--color-text-light);
    margin-bottom: rem(16);
  }

  &__warning {
    @include text-button;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(36);
    }

    @include media("mobile") {
      margin-top: rem(24);
    }
  }

  &__description {
    margin-top: rem(36);
    color: var(--color-text-light);
  }

  &__head {
    @include media("desktop") {
      margin-bottom: rem(32);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__head-title {
    @include text-button;
    margin-bottom: rem(8);
  }

  &__head-description {
    @include text-18;
    color: var(--color-text-light);
  }

  &__items {
    @include media("desktop") {
      display: flex;
      justify-content: space-between;
      gap: rem(32);
      align-items: stretch;

      &--grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(36) rem(32);
      }

      &--grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(36) rem(32);
      }
    }

    @include media("mobile") {
      display: block;

      &--mobile-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(32);
      }

      &:not(&--mobile-grid) .tickets__item {
        &:first-child {
          // border-top: none;
          // padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @include media("desktop") {
      width: 100%;
    }
  }

  &__items:not(&__items--mobile-grid) &__item {
    @include media("mobile") {
      border-top: 1px solid var(--color-border);
      padding-top: rem(24);
      padding-bottom: rem(24);
    }
  }

  &__items--mobile-grid &__item {
    @include media("mobile") {
      &:nth-child(n + 3) {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: rem(-16);
          left: 0;
          right: 0;
          border-top: 1px solid var(--color-border);
        }
      }

      &:nth-child(even) {
        &:after {
          left: rem(-16);
        }
      }

      &:nth-child(odd) {
        &:after {
          right: rem(-16);
        }
      }

      &:nth-child(even) {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: rem(-16);
          bottom: 0;
          border-left: 1px solid var(--color-border);
        }
      }
    }
  }

  &__item-name {
    margin-bottom: rem(12);

    @include media("desktop") {
      @include text-16-desktop;
    }

    @include media("mobile") {
      @include text-14-16;
    }
  }

  &__item-value {
    @include h2;
    margin-top: auto;
  }

  &__item-also {
    @include text-14-16;
    color: var(--color-text-light);
    margin-top: rem(12);

    &:after {
      content: ".";
      opacity: 0;
    }

    @include media("desktop") {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      display: block;
    }
  }

  &__items:not(&__items--mobile-grid) &__item-also {
    @include media("mobile") {
      &:after {
        content: none;
      }

      &:empty {
        display: none;
      }
    }
  }

  &__items:not(&__items--mobile-grid) &__item:first-child {
    @include media("mobile") {
      border-top: none;
      padding-top: 0;
    }
  }
}
