.visit {
  @include media("desktop") {
    margin-bottom: rem(136);
  }

  @include media("mobile") {
    margin-bottom: rem(60);
  }

  &__header {
    @include media("desktop") {
      padding-top: rem(100);
      padding-bottom: rem(18);
      margin-bottom: rem(36);
      border-bottom: 1px solid var(--color-border);
    }

    @include media("mobile") {
      padding-top: rem(80);
    }
  }
  &__header-title {
    margin: 0;
  }
}
