.content-slider {
  position: relative;
  overflow: hidden;

  @include media("desktop") {
    margin-top: rem(48);
    margin-bottom: rem(48);
  }

  @include media("mobile") {
    margin-top: rem(40);
    margin-bottom: rem(48);
  }

  &__slider {
    overflow: visible;
  }

  &__slide {
    width: auto;

    @include media("desktop") {
      height: rem(424);
    }

    @include media("mobile") {
      height: rem(204);
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(20);
  }

  &__pagination {
    @include text-16;
    color: var(--color-text-light);
  }
}
