.pushka-events {
  @include media("desktop") {
    margin-top: rem(64);
  }

  @include media("mobile") {
    margin-top: rem(100);
  }

  &__header {
    @include text-button;
    padding-bottom: rem(20);
  }

  &__list {
    @include media("desktop") {
      @include cells-list(2);
    }
  }

  &__item {
    @include media("desktop") {
      @include cells-item(2);
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }

  &__btn {
    @include media("mobile") {
      margin-top: rem(16);
    }
  }
}
