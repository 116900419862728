.breadcrumbs-horizontal {
  @include media("mobile") {
    background-color: var(--color-border);
  }

  a {
    @include text-button;
    color: var(--color-text);

    &:hover,
    &.active {
      color: var(--color-text-light);
    }

    @include media("desktop") {
      margin-right: rem(20);
    }

    @include media("mobile") {
      padding: rem(16);
      display: block;
      position: relative;

      & + a {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-top: 1px solid var(--color-text-light);
          opacity: 0.2;
        }
      }
    }
  }
}
