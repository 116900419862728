.intro {
  @include media("desktop") {
    padding-top: rem(4);
    margin-top: rem(-102);
    margin-bottom: rem(48);
  }

  @include media("mobile") {
    margin-bottom: rem(60);
  }

  &__container {
    @include media("desktop") {
      padding-right: rem(512);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      min-height: rem(650);
    }

    @include media("mobile") {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    @include text-16;

    @include media("desktop") {
      z-index: 8;
      margin-left: rem(216);
      padding-left: rem(40);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      margin-bottom: auto;
      max-height: rem(240);
      overflow: auto;
    }

    @include media("mobile") {
      order: 3;
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__info-group {
    @include media("desktop") {
      padding-top: rem(16);
      padding-bottom: rem(16);

      & + & {
        border-top: 1px solid var(--color-border);
      }
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
    }
  }

  &__info-section {
    @include media("desktop") {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: rem(2);
      padding-bottom: rem(2);
      gap: rem(12);

      svg {
        position: absolute;
        top: 0;
        left: rem(-40);
        width: rem(24);
        height: rem(24);
      }

    }

    @include media("mobile") {
      display: flex;
      flex-direction: column;
      gap: rem(4);
      padding-bottom: rem(21);
      display: none;

      svg {
        display: none;
      }
    }

    &-working-hours-desktop {
      &-content {
        br {
          content: "";
          display: block;
          font-size: 100%;
          height: rem(10);
        }

        mark {
          background-color: transparent;
          color: var(--color-text-light);
        }
      }

      @include media("desktop") {
        display: block;
      }
      @include media("mobile") {
        display: none;
      }
    }

    &-working-hours-mobile {
      &-content {
        br {
          content: "";
          display: block;
          font-size: 100%;
          height: rem(10);
        }

        mark {
          background-color: transparent;
          color: var(--color-text-light);
        }
      }

      @include media("desktop") {
        display: none;
      }
      @include media("mobile") {
        display: block;
      }
    }
  }

  &__info-toggle {
    @include media("desktop") {
      display: none;
    }

    @include media("mobile") {
      @include text-button;
      color: var(--color-text-light);
      padding-top: rem(15);
      padding-bottom: rem(15);
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        display: block;
        height: rem(24);
        width: rem(24);
      }
    }
  }

  &__info-group.active &__info-toggle svg {
    @include media("mobile") {
      transform: rotate(180deg);
    }
  }

  &__info-group.active &__info-section {
    @include media("mobile") {
      display: flex;
    }
  }

  &__info-also {
    @include media("desktop") {
      margin-top: rem(28);
    }

    @include media("mobile") {
      margin-top: rem(10);
    }
  }

  &__slider {
    @include media("desktop") {
      width: 100%;
    }
    @include media("mobile") {
      order: 2;
      margin-left: rem(-16);
      margin-right: rem(-16);
    }
  }

  &__slider-slide {
    @include media("desktop") {
      @include img-fit(728, 360);
    }

    @include media("mobile") {
      @include img-fit(360, 320);
    }
  }

  &__slider-pagination {
    display: flex;
    position: relative;
    z-index: 1;
    width: auto !important;
    bottom: 0px !important;

    @include media("desktop") {
      padding-top: rem(48);
      margin-left: rem(-6);
      margin-right: rem(-6);
    }

    @include media("mobile") {
      margin-left: rem(12);
      margin-right: rem(12);
      margin-top: rem(-17);
      margin-bottom: rem(40);
    }
  }

  &__slider-bullet {
    position: relative;
    animation-duration: inherit;
    width: 100%;
    height: 1px;
    background-color: var(--color-border);

    @include media("desktop") {
      margin-left: rem(6);
      margin-right: rem(6);
    }

    @include media("mobile") {
      margin-left: rem(4);
      margin-right: rem(4);
    }

    &.active {
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: rem(4);
        left: 0;
        bottom: 0;
      }

      &:before {
        background-color: var(--color-border);
        transform: scaleY(0);
        transform-origin: 0% 100%;
        animation: bullet-y 0.2s ease forwards;
      }

      &:after {
        background-color: var(--color-text);
        transform: scaleX(0);
        transform-origin: 0% 50%;
        animation: bullet-x 1s linear forwards;
        animation-duration: inherit;
      }
    }
  }

  &__mobile-head {
    display: none;

    @include media("mobile") {
      display: block;
      order: 1;
      padding-top: rem(40);
      margin-bottom: rem(24);
    }
  }

  &__mobile-head-logo {
    display: block;
    width: rem(239);
    margin-bottom: rem(40);
  }

  &__mobile-head-btn {
    @include button;
    @include text-creme;
  }
}

@keyframes bullet-x {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes bullet-y {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
