@use "sass:math";

@mixin media($val) {
  @if $val == "mobile" {
    @media screen and (max-width: $break-tablet) {
      @content;
    }
  } @else if $val == "desktop" {
    @media screen and (min-width: #{$break-tablet+1}) {
      @content;
    }
  } @else {
    @media screen and (min-width: $val) {
      @content;
    }
  }
}

// @mixin media($val) {
//   @if $val == "tablet" {
//     @media screen and (min-width: #{$break-mobile+1}) and (max-width: $break-tablet) {
//       @content;
//     }
//   } @else if $val == "mobile" {
//     @media screen and (max-width: $break-mobile) {
//       @content;
//     }
//   } @else if $val == "mobile-small" {
//     @media screen and (max-width: $break-mobile-small) {
//       @content;
//     }
//   } @else if $val == "mobile-large" {
//     @media screen and (min-width: #{$break-mobile-small+1}) and (max-width: $break-mobile) {
//       @content;
//     }
//   } @else if $val == "tablet-and-mobile" {
//     @media screen and (max-width: $break-tablet) {
//       @content;
//     }
//   } @else if $val == "desktop" {
//     @media screen and (min-width: #{$break-tablet+1}) {
//       @content;
//     }
//   } @else if $val == "desktop-and-tablet" {
//     @media screen and (min-width: #{$break-mobile+1}) {
//       @content;
//     }
//   } @else if $val == "desktop-small" {
//     @media screen and (min-width: #{$break-tablet+1}) and (max-width: $break-desktop-small) {
//       @content;
//     }
//   } @else if $val == "desktop-medium" {
//     @media screen and (min-width: #{$break-desktop-small+1}) and (max-width: $break-desktop-medium) {
//       @content;
//     }
//   } @else if $val == "desktop-big" {
//     @media screen and (min-width: #{$break-desktop-medium+1}) {
//       @content;
//     }
//   } @else if $val == "desktop-medium-and-big" {
//     @media screen and (min-width: #{$break-desktop-small+1}) {
//       @content;
//     }
//   } @else if $val == "desktop-medium-and-small" {
//     @media screen and (min-width: #{$break-tablet+1}) and (max-width: $break-desktop-medium) {
//       @content;
//     }
//   } @else if $val == "desktop-oversize" {
//     @media screen and (min-width: #{$break-desktop-oversize+1}) {
//       @content;
//     }
//   } @else {
//     @media screen and (min-width: $val) {
//       @content;
//     }
//   }
// }

@function rem($val) {
  @return #{math.div($val, 16)}rem;
}

@mixin h1-mobile {
  @include media("mobile") {
    font-weight: 500;
    font-size: rem(35);
    line-height: rem(36);
  }
}

@mixin h1-desktop {
  @include media("desktop") {
    font-weight: 500;
    font-size: rem(48);
    line-height: rem(50);
  }
}

@mixin h2-mobile {
  @include media("mobile") {
    font-weight: 400;
    font-size: rem(27);
    line-height: rem(32);
  }
}

@mixin h2-desktop {
  @include media("desktop") {
    font-weight: 400;
    font-size: rem(34);
    line-height: rem(38);
  }
}

@mixin h3-mobile {
  @include media("mobile") {
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(24);
  }
}

@mixin h3-desktop {
  @include media("desktop") {
    font-weight: 400;
    font-size: rem(23);
    line-height: rem(28);
  }
}

@mixin h1 {
  @include h1-desktop;
  @include h1-mobile;
}

@mixin h2 {
  @include h2-desktop;
  @include h2-mobile;
}

@mixin h3 {
  @include h3-desktop;
  @include h3-mobile;
}

@mixin text-button {
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(24);
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin text-14-16 {
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(16);
}

@mixin text-12-14 {
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(14);
}

@mixin text-18-24 {
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(24);
}

@mixin text-16-mobile {
  @include media("mobile") {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(24);
  }
}

@mixin text-16-desktop {
  @include media("desktop") {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(20);
  }
}

@mixin text-16 {
  @include text-16-mobile;
  @include text-16-desktop;
}

@mixin text-18-desktop {
  @include media("desktop") {
    font-weight: 400;
    font-size: rem(18);
    line-height: rem(28);
  }
}

@mixin text-18 {
  @include text-18-desktop;
  @include text-16-mobile;
}

@mixin link-arrow {
  @include h2;
  color: var(--color-text);
  display: flex;
  align-items: flex-end;
  transition: color 0.4s ease-out;

  &:hover {
    color: var(--color-text-light);
  }

  svg {
    color: var(--color-text-light);
    pointer-events: none;
    width: rem(32);
  }
}

@mixin link-right {
  @include link-arrow;

  svg {
    margin-left: rem(10);
  }
}

@mixin link-left {
  @include link-arrow;

  svg {
    margin-right: rem(10);
  }
}

@mixin small-button {
  @include text-button;
  position: relative;
  z-index: 1;
  background-color: var(--color-bg);
  color: var(--color-accent);
  padding: rem(8) rem(22);
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-text-light);
    opacity: 0;
    transition: opacity 0.4s ease-out;
    pointer-events: none;
  }

  &:hover:before {
    opacity: 0.1;
  }

  &:active:before {
    opacity: 0.2;
  }
}

@mixin button {
  @include text-button;
  overflow: hidden;
  position: relative;
  z-index: 1;
  color: var(--color-accent);
  display: flex;
  align-items: center;
  justify-content: stretch;
  text-align: center;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-bg);

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-text-light);
    opacity: 0;
    transition: opacity 0.4s ease-out;
    pointer-events: none;
  }

  &:hover:before {
    opacity: 0.1;
  }

  &:active:before {
    opacity: 0.2;
  }

  @include media("desktop") {
    padding-top: rem(19);
    padding-bottom: rem(19);
  }

  @include media("mobile") {
    padding-top: rem(15);
    padding-bottom: rem(15);
  }

  &__figure {
    pointer-events: none;
    display: block;
    flex: 2 2 auto;
    transition: transform 0.4s ease-out;

    &--left {
      margin-right: rem(20);
      margin-left: rem(10);
      transform: translateX(-100%);

      svg {
        margin-left: auto;
      }
    }

    &--right {
      margin-left: rem(20);
      margin-right: rem(10);
      transform: translateX(100%);

      svg {
        margin-right: auto;
      }
    }
  }

  &:hover &__figure {
    &--left {
      transform: translateX(-40%);
    }

    &--right {
      transform: translateX(40%);
    }
  }

  svg {
    height: rem(10);
    width: rem(10);
  }

  &.disabled,
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;

    svg {
      display: none;
    }
  }
}

@mixin img-fit($width, $height, $fit: cover) {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    padding-bottom: #{math.div($height * 100, $width)}+ "%";
  }

  iframe,
  img {
    display: block;
    border: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: $fit;
  }
}

@mixin cells-list($columnsCount: 4) {
  display: grid;
  grid-template-columns: repeat($columnsCount, 1fr);
  gap: rem(72) rem(40);
  padding-top: rem(36);
  padding-bottom: rem(35);
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

@mixin cells-item($columnsCount: 4) {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
  }

  &::before {
    left: rem(-20.5);
    top: 0;
    bottom: 0;
    border-left: 1px solid var(--color-border);
  }

  &::after {
    left: rem(-20);
    right: rem(-20);
    bottom: rem(-36);
    border-bottom: 1px solid var(--color-border);
  }

  &:nth-child(#{$columnsCount}n + 1)::before {
    display: none;
  }

  &:nth-child(#{$columnsCount}n + 1)::after {
    left: rem(0);
  }

  &:nth-child(#{$columnsCount}n)::after {
    right: rem(0);
  }
}

@mixin animation($name, $delay: 0.2s, $duration: 0.6s, $function: ease-out) {
  opacity: 0;
  animation: #{$duration} #{$name} #{$delay} #{$function} forwards;
}
