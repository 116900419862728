.map {
  &__head {
    margin-bottom: rem(24);
  }

  &__head-title {
    @include text-button;
    margin-bottom: rem(8);
    color: var(--color-text-light);
  }

  &__img {
    position: relative;

    @include media("desktop") {
      height: rem(430);
      border: 1px solid var(--color-border);
    }

    @include media("mobile") {
      height: rem(340);
      margin-left: rem(-16);
      margin-right: rem(-16);
      border-top: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
