.news-list {
  @include media("desktop") {
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-bottom: rem(56);
  }

  @include media("mobile") {
    margin-bottom: rem(60);
  }

  &__header {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
      padding-top: rem(48);
      padding-bottom: rem(20);
      margin-bottom: rem(28);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media("mobile") {
      padding-top: rem(32);
      padding-bottom: rem(24);
    }
  }

  &__title {
    margin: 0;
    @include h1-mobile;

    @include media("desktop") {
      @include text-button;
    }
  }

  &__all {
    @include media("desktop") {
      margin-top: rem(20);
    }

    @include media("mobile") {
      margin-top: rem(16);
    }
  }

  &__list {
    @include media("desktop") {
      margin-left: rem(-20);
      margin-right: rem(-20);
      display: flex;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  &__item {
    @include media("desktop") {
      flex: 1 1 50%;
      padding-left: rem(20);
      padding-right: rem(20);
      min-height: rem(184);
      border-left: 1px solid var(--color-border);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      transition: opacity 0.4s ease-out;

      &:hover {
        opacity: 0.5;
      }

      &:first-child {
        border-left: none;
      }
    }

    @include media("mobile") {
      display: block;
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }

  &__item-date {
    @include h3;
    margin-bottom: rem(16);
    color: var(--color-text-light);
  }

  &__item-title {
    margin-top: auto;

    @include text-16-desktop;
    @include h3-mobile;

    text-transform: none;
  }
}
