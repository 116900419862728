.modal--gallery {
  background-color: rgba($color-black, 0.8);
}

.modal-gallery {
  &__btns {
    position: absolute;
    z-index: 2;
    top: rem(21);
    right: rem(16);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(20);
  }

  &__btn {
    display: block;
    border: none;
    height: rem(24);
    width: rem(24);
  }

  &__slide {
    height: 100vh;
    display: flex;

    img {
      margin: auto;
      max-height: calc(100vh - 10.5rem);
      max-width: calc(100vw - 2rem);
      object-fit: contain;
    }
  }

  &__controls {
    position: absolute;
    z-index: 2;
    bottom: rem(36);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pagination {
    width: auto;
    margin-left: rem(20);
    margin-right: rem(20);
  }
}
