.columns {
  display: flex;
  gap: rem(24);

  @include media("desktop") {
    justify-content: space-between;
  }

  @include media("mobile") {
    flex-direction: column;
  }

  &__item {
    @include text-18;
    width: auto;
  }
}
