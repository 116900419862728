.cookie-msg {
  background-color: var(--color-border);

  @include media("desktop") {
    padding-top: rem(12);
    padding-bottom: rem(12);
  }

  @include media("mobile") {
    padding-top: rem(16);
    padding-bottom: rem(16);
  }

  &__container {
    @include media("desktop") {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    @include text-18;
    @include media("mobile") {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(8);
    }

    a {
      color: $color-link;
    }
  }

  &__btn {
    @include small-button;
    margin-left: auto;
    flex: 0 0 auto;

    @include media("desktop") {
      width: auto;
    }

    @include media("mobile") {
      width: 100%;
      margin-top: rem(16);
    }

    &:after {
      @include media("desktop") {
        content: attr(data-text-desktop);
      }
      @include media("mobile") {
        content: attr(data-text-mobile);
      }
    }
  }
}
