.article {
  &__header {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
      padding-bottom: rem(18);
      margin-bottom: rem(36);
      display: grid;
      grid-gap: rem(12);
      grid-template-areas:
        "type type type"
        "title . date";
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__header-type {
    @include text-16;
    color: var(--color-text-light);
    grid-area: type;

    @include media("desktop") {
    }

    @include media("mobile") {
      margin-bottom: rem(8);
    }
  }

  &__header-title {
    margin: 0;
    grid-area: title;

    @include media("desktop") {
      max-width: rem(780);
    }
  }

  &__header-date {
    @include h3-desktop;
    @include text-16-mobile;
    color: var(--color-text-light);
    grid-area: date;

    @include media("desktop") {
      margin-top: auto;
      margin-left: auto;
    }

    @include media("mobile") {
      margin-top: rem(12);
    }
  }

  &__block {
    @include media("desktop") {
      & ~ & {
        margin-top: rem(100);
      }
    }

    @include media("mobile") {
      margin-bottom: rem(24);

      & ~ & {
        margin-top: rem(60);
      }
    }

    &.no-margin{
      margin-top: 0;
    }

  }
}
