.shop-card {
  transition: opacity 0.4s ease-out;

  &[href]:hover {
    opacity: 0.5;
  }

  &__grid {
    display: grid;

    @include media("desktop") {
      grid-gap: rem(36) rem(20);
      grid-template-columns: repeat(4, 1fr);
    }

    @include media("mobile") {
      grid-gap: rem(32) rem(16);
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__img {
    @include img-fit(188, 260);
    width: 100%;
    margin-bottom: rem(8);

    &:before {
      background-color: var(--color-bg);
    }

    img {
      mix-blend-mode: multiply;
    }
  }

  &__title {
    @include text-18;
    margin-bottom: rem(4);
  }

  &__price {
    @include text-18;
    font-weight: 500 !important;
  }
}
