.event {
  &__header {
    border-top: 1px solid var(--color-border);

    @include media("desktop") {
      padding-top: rem(20);
      padding-bottom: rem(20);
    }

    @include media("mobile") {
      padding-top: rem(12);
      padding-bottom: rem(24);
    }
  }

  &__header-img {
    @include media("desktop") {
      @include img-fit(460, 460, contain);
      width: rem(460);
      margin: rem(40) auto;
    }
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(40);
  }

  &__header-top-type {
    @include text-16;
  }

  &__header-top-age {
    @include text-14-16;
    border: 1px solid var(--color-border);
    border-radius: rem(11);
    padding: rem(2) rem(8);
  }

  &__header-type {
    @include text-16;
    margin-top: auto;

    @include media("desktop") {
      margin-bottom: rem(12);
    }

    @include media("mobile") {
      margin-bottom: rem(8);
    }
  }

  &__header-title {
    margin-bottom: 0;
  }
}
