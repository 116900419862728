.pushka {
  &__logo {
    @include media("mobile") {
      margin-bottom: 60px;
    }
  }

  &__logo-img-1 {
    @include media("desktop") {
      margin-top: rem(-40);
      width: rem(558);
    }

    @include media("mobile") {
      margin-top: rem(24);
    }
  }

  &__logo-img-2 {
    @include media("desktop") {
      margin-top: rem(32);
    }

    @include media("mobile") {
      margin-top: rem(16);
    }
  }

  &__title {
    @include media("desktop") {
      margin-bottom: rem(80);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__subtitle {
    @include media("desktop") {
      margin-bottom: rem(20);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__grid {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__steps {
    counter-set: steps;
  }

  &__steps-item {
    @include text-18;
    counter-increment: steps;
    border-top: 1px solid var(--color-border);

    @include media("desktop") {
      position: relative;
      padding-top: rem(36);
      padding-bottom: rem(20);
      margin-left: rem(-20);
      padding-left: rem(20);
      padding-right: rem(176);

      &:after {
        @include h2;
        content: counter(steps);
        position: absolute;
        bottom: rem(20);
        right: 0;
        flex: 0 0 auto;
        display: block;
        width: rem(128);
        text-align: right;
      }
    }

    @include media("mobile") {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: rem(16);
      padding-bottom: rem(24);

      &:before {
        @include h2;
        content: counter(steps);
        flex: 0 0 auto;
        display: block;
        width: rem(70);
        margin-right: rem(16);
      }
    }
  }
}
