.footer {
  background-color: var(--color-bg);

  @include media("desktop") {
    padding-top: rem(40);
    padding-bottom: rem(20);
  }

  @include media("mobile") {
    position: relative;
    z-index: 8;
    padding-top: rem(24);
    padding-bottom: rem(48);
  }

  .footer-divider--mobile & {
    @include media("mobile") {
      border-top: 1px solid var(--color-border);
    }
  }

  &__container {
    @include media("desktop") {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  .footer-divider--desktop & {
    @include media("desktop") {
      padding-top: 0;
    }
  }

  .footer-divider--desktop &__container {
    @include media("desktop") {
      border-top: 1px solid var(--color-border);
      padding-top: rem(40);
    }
  }

  &__logo {
    display: block;
    margin-right: auto;

    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__logo-text {
    width: rem(134);
  }

  &__logo-icon {
    @include media("desktop") {
      width: rem(256);
    }

    @include media("mobile") {
      width: rem(217);
    }
  }

  &__info {
    @include media("desktop") {
      width: rem(728);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__nav {
    @include media("desktop") {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem(8) rem(40);
      margin-bottom: rem(20);
    }

    @include media("mobile") {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      margin-bottom: rem(40);
    }

    a {
      @include text-button;
    }
  }

  &__contacts {
    @include media("desktop") {
      margin-top: auto;
      display: flex;
      align-items: stretch;
    }
  }

  &__contacts {
    @include media("desktop") {
      border-top: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);
      padding-top: rem(20);
      padding-bottom: rem(20);
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
    }
  }

  &__contacts-block {
    @include media("desktop") {
      width: 50%;
      padding-left: rem(20);
      padding-right: rem(20);

      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
        border-left: 1px solid var(--color-border);
      }
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__contacts-title {
    @include text-button;
    margin-bottom: rem(12);
  }

  &__contacts-content {
    @include text-14-16;

    mark {
      background-color: transparent;
      color: var(--color-text-light);
    }
  }

  &__copy {
    display: flex;

    @include media("desktop") {
      padding-top: rem(16);
      justify-content: space-between;
    }

    @include media("mobile") {
      padding-top: rem(24);
      flex-direction: column;
    }
  }

  &__copy-link {
    @include text-14-16;

    & + & {
      @include media("desktop") {
        margin-left: rem(32);
      }
      @include media("mobile") {
        margin-top: rem(12);
      }
    }
  }

  &__copy-divider {
    @include media("desktop") {
      margin-left: auto;
      margin-right: auto;
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
      width: 100%;
      margin-top: rem(24);
      margin-bottom: rem(24);
    }
  }
}
