.menu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-bg);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  visibility: hidden;
  opacity: 0;
  transform: translateX(100px);
  transition: 0.4s ease-out;
  transition-property: transform, opacity, visibility;

  @include media("desktop") {
    height: 100vh;
  }

  body.menu-open & {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }

  body.header-always & {
    @include media("desktop") {
      opacity: 1;
      visibility: visible;
      overflow: visible;
      transform: translateY(0px);
      height: rem(634);
      border-left: 1px solid var(--color-border);
      width: calc(100% + 1.25rem + (50vw - 38.8rem) - 17px);
    }
  }

  @include media("desktop") {
    overflow: auto;
    left: rem(-20);
    top: rem(-20);
    height: 100vh;
    width: calc(100% + 2.5rem);
    width: calc(100% + 1.25rem + (50vw - 38.8rem));
    padding-right: rem(20);
    padding-right: calc(50vw - 38.75rem);
    padding-top: rem(20);
    padding-left: rem(20);
    padding-bottom: rem(24);
  }

  @include media("mobile") {
    position: fixed;
    height: 100%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    padding-left: rem(16);
    padding-right: rem(16);
    padding-bottom: rem(32);
  }

  &__scroll {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    @include media("desktop") {
      height: rem(634);
    }
  }

  &__logo {
    margin-bottom: auto;

    @include media("desktop") {
      width: rem(216);
    }

    @include media("mobile") {
      width: rem(154);
      // max-width: 24.0625vh;
      max-width: calc(100vh - 24rem);
      margin-left: 1px;
      margin-top: auto;
      padding-top: rem(32);
      padding-bottom: rem(20);
    }
  }

  &__footer {
    @include media("desktop") {
      padding-top: rem(34);
    }

    @include media("mobile") {
      padding-top: rem(34);
    }
  }

  &__footer-link {
  }

  &__in {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__level-1 {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__level-1-links {
    position: relative;

    @include media("desktop") {
      padding-top: rem(34);
    }
  }

  &__link-1 {
    @include link-right;
    position: relative;

    & + & {
      margin-top: rem(8);
    }

    &.current {
      color: var(--color-text-light);
    }
  }

  &__link-pushkin {
    position: absolute;
    bottom: 0;
    right: 0;
    transition: color 0.4s ease-out;

    &:hover {
      color: var(--color-text-light);
    }

    @include media("desktop") {
      width: rem(124);
    }

    @include media("mobile") {
      width: rem(70);
    }
  }

  &__level-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);
    flex-direction: column;
    justify-content: space-between;

    display: flex;

    opacity: 0;
    visibility: hidden;

    transition: 0.4s ease-out;
    transition-property: opacity, visibility;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    @include media("desktop") {
      margin-left: rem(-20);
      width: calc(100% + 2.5rem);
      padding-left: rem(20);
      padding-right: rem(20);
    }

    @include media("mobile") {
      margin-left: rem(-16);
      width: calc(100% + 2rem);
      padding-left: rem(16);
      padding-right: rem(16);
    }
  }

  &__level-2-back {
    @include link-left;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(84);
      margin-bottom: rem(42);
    }

    @include media("mobile") {
      margin-top: rem(58);
      margin-bottom: rem(34);
    }
  }

  &__level-2-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;

    @include media("desktop") {
      gap: rem(8);
    }

    @include media("mobile") {
      gap: rem(16);
    }
  }

  &__link-2 {
    @include text-button;
    @extend .link;
    position: relative;

    &.current {
      color: var(--color-text-light);
    }
  }

  &__overlay {
    @include media("desktop") {
      background: $color-black;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      height: calc(100vh + 100px);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.4s ease-out;

      body.menu-open & {
        opacity: 0.2;
        pointer-events: all;
      }

      body.menu-open.theme-push &,
      body.menu-open.theme-blue & {
        opacity: 0.3;
      }
    }
  }
}
