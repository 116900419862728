.digits {
  border-bottom: 1px solid var(--color-border);

  @include media("desktop") {
    padding-bottom: rem(36);
    margin-bottom: rem(36);
  }

  @include media("mobile") {
    padding-bottom: rem(32);
    margin-bottom: rem(24);
  }

  &__title {
    @include text-button;
    color: var(--color-text-light);
    margin-bottom: rem(16);
  }

  &__description {
    margin-top: rem(36);
    color: var(--color-text-light);
  }

  &__items {
    @include media("desktop") {
      display: flex;
      justify-content: space-between;
      gap: rem(32);
      align-items: stretch;
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;

    @include media("desktop") {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      // max-width: 44%;
    }

    @include media("mobile") {
      & + & {
        margin-top: rem(24);
      }
    }
  }

  &__item-name {
    @include media("desktop") {
      @include text-18-desktop;
      margin-bottom: rem(8);
    }

    @include media("mobile") {
      @include text-14-16;
      margin-bottom: rem(12);
    }
  }

  &__item-value {
    @include h2;
    margin-top: auto;
  }

  &__item-also {
    @include text-14-16;
    color: var(--color-text-light);
    margin-top: rem(12);

    @include media("desktop") {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      display: block;

      &:after {
        content: ".";
        opacity: 0;
      }
    }

    @include media("mobile") {
      &:empty {
        display: none;
      }
    }
  }
}
