.contacts {
  margin-bottom: rem(100);

  &__header {
    border-bottom: 1px solid var(--color-border);

    @include media("desktop") {
      margin-top: rem(100);
      margin-bottom: rem(36);
    }

    @include media("mobile") {
      margin-top: rem(80);
      margin-bottom: rem(24);
    }
  }

  &__primary {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    @include media("desktop") {
      margin-bottom: rem(60);
    }

    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__primary-link {
    @include h2;
    transition: opacity 0.4s ease-out;

    &[href]:hover {
      opacity: 0.5;
    }
  }

  &__address {
    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__address-text {
    @include h2;
  }

  &__map {
    display: block;
    text-decoration: none;

    @include media("desktop") {
      border: 1px solid var(--color-border);
    }

    @include media("mobile") {
      @include img-fit(362, 340);
      margin-left: rem(-16);
      margin-right: rem(-16);
      margin-bottom: rem(40);
      border-top: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__schedule {
    @include media("desktop") {
      padding-top: rem(48);
    }

    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__schedule-row {
    @include text-18;
    @include media("desktop") {
      margin-top: rem(8);
    }

    @include media("mobile") {
      margin-top: rem(4);
    }
  }

  &__schedule-also {
    @include text-14-16;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(29);
    }

    @include media("mobile") {
      margin-top: rem(40);
      max-width: rem(300);
    }
  }

  &__items {
    @include media("desktop") {
      padding-top: rem(48);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(40) rem(32);
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
    }
  }

  &__item {
    @include media("mobile") {
      margin-top: rem(24);
    }
  }

  &__item-row {
    @include text-18;

    a {
      transition: opacity 0.4s ease-out;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
