.article-nav {
  @include media("desktop") {
    position: sticky;
    top: rem(16);
    left: 0;
    padding-bottom: rem(65);

    transition: top 0.4s ease-out;

    .body--scroll-up & {
      top: rem(81);
    }
  }

  @include media("mobile") {
    margin-bottom: rem(60);
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include media("desktop") {
      gap: rem(16);
    }

    @include media("mobile") {
      background-color: var(--color-border);
    }
  }

  &__link {
    @include text-button;
    color: var(--color-text);

    &:hover,
    &.active {
      color: var(--color-text-light);
    }

    @include media("mobile") {
      padding: rem(16);
      position: relative;

      &:not(:first-child):before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid var(--color-text-light);
        opacity: 0.2;
      }
    }
  }

  &__section {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
      margin-bottom: rem(36);
      padding-bottom: rem(12);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(24);

      & ~ & {
        margin-top: rem(100);
      }
    }

    @include media("mobile") {
      margin-bottom: rem(24);

      & ~ & {
        margin-top: rem(60);
      }
    }
  }

  &__section-title {
    @include h2;
  }

  &__section-info {
    @include text-14-16;
    color: var(--color-text-light);

    @include media("desktop") {
      text-align: right;
      max-width: rem(330);
    }

    @include media("mobile") {
      border-top: 1px solid var(--color-border);
      margin-top: rem(16);
      padding-top: rem(24);
    }

    &:empty {
      @include media("mobile") {
        padding-top: 0;
      }
    }
  }
}
