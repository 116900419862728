body.body--visible {
  .from {
    &-left {
      @include media("desktop") {
        @include animation(fromLeft);
      }
    }

    &-right {
      @include media("desktop") {
        @include animation(fromRight);
      }
    }

    &-top {
      @include media("desktop") {
        @include animation(fromTop);
      }
    }

    &-bottom {
      @include media("desktop") {
        @include animation(fromBottom);
      }
    }
  }

  .intro__info {
    @include media("desktop") {
      @include animation(fromLeft);
    }
  }

  &.header-always .header__logo-text {
    @include media("desktop") {
      @include animation(fromRight);
    }
  }

  &.header-always .menu__logo {
    @include media("desktop") {
      @include animation(fromBottom);
    }
  }

  &:not(.header-always) .header__logo-text {
    @include media("desktop") {
      @include animation(fromTop);
    }
  }

  &:not(.header-always) .header__right {
    @include media("desktop") {
      @include animation(fromTop);
    }
  }

  .menu__level-1.active {
    .menu__level-1-links {
      @include media("desktop") {
        @include animation(fromRight);
      }
    }
  }

  .menu__level-2.active {
    .menu__level-2-back {
      @include media("desktop") {
        @include animation(fromRight, 0.4s, 0.4s);
      }
    }
    .menu__level-2-links {
      @include media("desktop") {
        @include animation(fromRight);
      }
    }
  }

  .grid__aside {
    @include media("desktop") {
      @include animation(fromRight);
    }
  }

  .grid__main {
    @include media("desktop") {
      @include animation(fromLeft);
    }
  }

  .article__header-title,
  .article__header-type {
    @include media("desktop") {
      @include animation(fromRight);
    }
  }

  .article__header-date {
    @include media("desktop") {
      @include animation(fromLeft);
    }
  }

  .contacts__header,
  .visit__header {
    @include media("desktop") {
      @include animation(fromRight);
    }
  }

  .modal.modal--show .picture-modal {
    @include media("desktop") {
      @include animation(fromRight, 0s, 0.4s);
    }
  }
}

@keyframes fromLeft {
  from {
    opacity: 0;
    transform: translateX(-2rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fromRight {
  from {
    opacity: 0;
    transform: translateX(2rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fromTop {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fromBottom {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
