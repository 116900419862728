.picture-card {
  display: block;

  &__img {
    margin-bottom: rem(24);
    transition: 0.4s ease-out;
    transform: scale(1);
    transition-property: transform, filter;
  }

  &__title {
    @include text-16;
    margin-top: auto;
    transition: opacity 0.4s ease-out;
  }

  &__description {
    @include text-16;
    color: var(--color-text-light);
    margin-top: rem(4);
    transition: opacity 0.4s ease-out;
  }

  &:hover &__img {
    filter: drop-shadow(0px 8px 30px var(--color-border));

    @include media("desktop") {
      transform: scale(1.02);
    }
  }

  &:hover &__title {
    opacity: 0.5;
  }

  &:hover &__description {
    opacity: 0.5;
  }
}
