.events-slider {
  @include media("desktop") {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-bottom: rem(28);
  }

  @include media("mobile") {
    padding-bottom: rem(24);
  }

  &__header {
    @include media("desktop") {
      border-bottom: 1px solid var(--color-border);
      padding-top: rem(28);
      padding-bottom: rem(20);
      margin-bottom: rem(28);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media("mobile") {
      padding-top: rem(32);
      padding-bottom: rem(24);
    }
  }

  &__nav {
    @include media("desktop") {
      display: flex;
      gap: rem(16);
    }

    @include media("mobile") {
      display: none;
    }

    [disabled],
    .disabled {
      opacity: 0.2;
    }
  }

  &__nav-next,
  &__nav-prev {
    width: rem(40);
  }

  &__title {
    margin: 0;
    @include media("desktop") {
      @include text-button;
    }

    @include h1-mobile;
  }

  &__slider {
    @include media("desktop") {
      margin-left: rem(-20);
      margin-right: rem(-20);
    }
  }

  &__swiper-wrapper {
    @include media("mobile") {
      display: block;
    }
  }

  &__slide {
    @include media("desktop") {
      padding-left: rem(20);
      padding-right: rem(20);
      &.swiper-slide-active {
        border-right: 1px solid var(--color-border);
      }
    }

    @include media("mobile") {
      width: 100% !important;
      padding-top: rem(24);
      padding-bottom: rem(24);
      border-top: 1px solid var(--color-border);
    }
  }

  &__all {
    @include media("desktop") {
      margin-top: rem(28);
    }

    @include media("mobile") {
      margin-top: rem(16);
    }
  }
}
