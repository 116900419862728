.event-msg {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-top: 1px solid var(--color-border);

  @include media("desktop") {
    padding-top: rem(20);
    padding-bottom: rem(28);
  }

  @include media("mobile") {
    padding-top: rem(16);
    padding-bottom: rem(40);
  }

  &__container {
    position: relative;

    @include media("desktop") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    height: rem(24);
    width: rem(24);
  }

  &__content {
    @include media('desktop') {
      width: rem(624);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__head {
    @include media('desktop') {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }

    @include media('mobile') {

    }
  }

  &__title {
    @include text-button;
    @include media('desktop') {

    }

    @include media('mobile') {

    }
  }

  &__date {
    @include text-button;
    color: var(--color-text-light);

    @include media('desktop') {
      display: inline-flex;

      &:before {
        content: "|";
        margin-left: rem(12);
        margin-right: rem(12);
      }
    }

    @include media('mobile') {

    }
  }

  &__subtitle {
    color: var(--color-text-light);

    @include media('desktop') {
      @include text-14-16;
      margin-top: rem(16);
      margin-bottom: rem(4);
    }

    @include media('mobile') {
      @include text-12-14;
      margin-top: rem(20);
      margin-bottom: rem(8);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__link {
    @extend .link;
    @include media('desktop') {
      @include text-18-24;
    }

    @include media('mobile') {
      @include text-14-16;
      margin-top: rem(8);
    }
  }

  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #422727;

    @include media('desktop') {
      margin-top: rem(8);
      margin-bottom: rem(32);
    }

    @include media('mobile') {
      margin-bottom: rem(20);
    }


    svg, img {
      width: auto;

      @include media('desktop') {
        max-height: rem(36);
        max-width: rem(300);
      }

      @include media('mobile') {
        max-height: rem(24);
        max-width: rem(160);
      }
    }
  }

  &__btn {
    @include button;
    margin-top: auto;
  }

  &__image {
    @include media('desktop') {
      position: relative;
      overflow: hidden;
      z-index: -1;
      margin-left: auto;
      width: rem(532);
      width: calc(50vw - 38.75rem + 33.25rem - 1px);
      margin-top: rem(-20);
      margin-right: rem(-20);
      margin-right: calc(38.75rem - 50vw);
      margin-bottom: rem(-38);
    }

    @include media('mobile') {
      margin-top: rem(20);
      margin-left: rem(-16);
      margin-right: rem(-16);
    }

    img {
      @include media('desktop') {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__image-desktop {
    @include media('desktop') {
      width: rem(720);
      margin-right: auto;
    }

    @include media('mobile') {
      display: none;
    }
  }

  &__image-mobile {
    width: 100%;
    @include media('desktop') {
      display: none;
    }
  }
}
