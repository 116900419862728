.header {
  position: relative;
  z-index: 10;
  background-color: var(--color-bg);

  @include media("desktop") {
    body:not(.header-always) & {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    .body--header-small:not(.header-always) & {
      transform: translateY(-100%);
      transition: transform 0.4s ease-out;
      transition-property: transform, background-color;
      border-bottom: 1px solid var(--color-border);
    }

    .body--header-small.body--scroll-up:not(.header-always) & {
      transform: translateY(0%);
    }

    .body--header-top-transparent:not(.body--header-small) & {
      background-color: transparent;
    }
  }

  @include media("mobile") {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: flex-start;

    @include media("desktop") {
      padding-top: rem(20);
      height: rem(102);

      .body--header-small:not(.header-always) & {
        height: rem(64);
      }
    }

    @include media("mobile") {
      align-items: center;
      height: rem(44);
    }
  }

  &__logo {
    display: block;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }

  &__logo-text {
    display: none;

    @include media("desktop") {
      display: block;
      width: rem(134);

      .body--header-small:not(.header-always) & {
        display: none;
      }
    }
  }

  &__logo-icon {
    display: none;

    @include media("desktop") {
      width: rem(28);

      .body--header-small:not(.header-always) & {
        display: block;
        margin-top: rem(-6);
      }
    }

    @include media("mobile") {
      display: block;
      width: rem(21.6);
      margin-right: rem(-21.75);
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    @include media("desktop") {
      width: rem(472);
      flex-direction: row-reverse;
    }

    @include media("mobile") {
      width: 100%;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: rem(-10);
        left: rem(-16);
        width: calc(100% + rem(32));
        margin-top: -1px;
        height: 1px;
      }
    }
  }

  &__lang {
    position: relative;
    z-index: 2;
    display: flex;

    @include media("mobile") {
      display: none;
    }

    a {
      @include text-button;
      display: block;
      color: var(--color-text-light);

      @include media("desktop") {
        margin-left: rem(8);
      }

      @include media("mobile") {
        margin-right: rem(12);
      }

      &.active {
        color: var(--color-text);
      }
    }
  }

  &__toggle {
    @include text-button;
    position: relative;
    z-index: 2;
    width: auto;

    display: flex;
    align-items: center;

    @include media("mobile") {
      margin-left: auto;
    }
  }

  &__toggle-icon {
    position: relative;
    display: block;
    margin-right: rem(10);
    color: var(--color-text-light);
    width: rem(16);
    height: rem(10);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: rem(20);
      width: rem(20);
      transform: translate(-50%, -50%);

      path {
        transition: 0.4s ease-in-out;
        transition-property: opacity, transform;
      }

      #burger-line-1 {
        transform-origin: 24% 30%;
      }

      #burger-line-3 {
        transform-origin: 24% 70%;
      }
    }
  }

  body.menu-open &__toggle-icon {
    #burger-line-1 {
      transform: rotate(45deg) scaleX(1.25);
    }

    #burger-line-2 {
      transform: scaleX(0);
    }

    #burger-line-3 {
      transform: rotate(-45deg) scaleX(1.25);
    }
  }

  body.menu-open &__logo {
    @include media("mobile") {
      display: none;
    }
  }

  body.menu-open &__lang {
    @include media("mobile") {
      display: flex;
    }
  }

  body.header-always &__toggle {
    @include media("desktop") {
      display: none;
    }
  }

  body.header-always & {
    @include media("desktop") {
      z-index: 8;
      background-color: transparent;
    }
  }

  body.header--theme-base & {
    @extend .theme-base;
  }

  body.header--theme-creme & {
    @extend .theme-creme;
  }

  body.header--theme-blue & {
    @extend .theme-blue;
  }

  body.header--theme-push & {
    @extend .theme-push;
  }
}

body.header--theme-creme,
body.header--theme-base,
body.header--theme-blue,
body.header--theme-push {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;

    @include media("desktop") {
      height: rem(103);
    }

    @include media("mobile") {
      height: rem(45);
    }
  }
}

body.header--theme-base:before {
  @include bg-base;
}

body.header--theme-creme:before {
  @include bg-creme;
}

body.header--theme-blue:before {
  @include bg-blue;
}

body.header--theme-push:before {
  @include bg-push;
}
