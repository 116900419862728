.news-card {
  @include h3;
  transition: opacity 0.4s ease-out;

  &:hover {
    opacity: 0.5;
  }

  @include media("desktop") {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: rem(192);
  }

  @include media("mobile") {
    display: block;
  }

  &__date {
    color: var(--color-text-light);
    margin-bottom: auto;
  }

  &__description {
    margin-top: rem(16);
  }
}
