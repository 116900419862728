.culture {
  overflow: hidden;

  @include media("desktop") {
    padding-top: rem(120);
    padding-bottom: rem(120);
  }

  @include media("mobile") {
    padding-top: rem(60);
    padding-bottom: rem(40);
    overflow: hidden;
  }

  &__container {
    position: relative;
    padding-top: 1px;

    @include media("desktop") {
      width: rem(932);
    }

    @include media("mobile") {
    }
  }

  &__img {
    @include media("desktop") {
      width: rem(808);
      margin-left: auto;
      margin-right: auto;
    }

    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__figure-1,
  &__figure-2 {
    color: var(--color-border);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("desktop") {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto rem(24);
      height: rem(248);
      width: rem(517);
    }

    @include media("mobile") {
      height: rem(220);
      width: rem(460);
    }
  }

  &__figure-1 {
    @include media("desktop") {
      left: 100%;
    }

    @include media("mobile") {
      display: none;
    }
  }

  &__figure-2 {
    @include media("desktop") {
      right: 100%;
    }

    @include media("mobile") {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
