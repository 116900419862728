@import "../../../node_modules/normalize.css/normalize.css";
@import "../../../node_modules/swiper/swiper-bundle.css";

@import "fonts";
@import "variables";
@import "theme";
@import "mixins";
@import "main";

@import "blocks/menu";
@import "blocks/header";
@import "blocks/footer";
@import "blocks/grid";
@import "blocks/modal";
@import "blocks/animation";

@import "blocks/select";
@import "blocks/event-card";
@import "blocks/events-slider";
@import "blocks/picture-card";
@import "blocks/news-list";
@import "blocks/future";
@import "blocks/intro";
@import "blocks/breadcrumbs-horizontal";
@import "blocks/breadcrumbs-float";
@import "blocks/exposition";
@import "blocks/address";
@import "blocks/content-slider";
@import "blocks/gallery-slider";
@import "blocks/inset-1";
@import "blocks/inset-2";
@import "blocks/inset-3";
@import "blocks/events";
@import "blocks/event";
@import "blocks/links";
@import "blocks/events-list";
@import "blocks/visit";
@import "blocks/article-nav";
@import "blocks/article-mobile-nav";
@import "blocks/shop-card";
@import "blocks/faq";
@import "blocks/digits";
@import "blocks/tickets";
@import "blocks/pushkin";
@import "blocks/map";
@import "blocks/pictures-list";
@import "blocks/picture-modal";
@import "blocks/about";
@import "blocks/team";
@import "blocks/news";
@import "blocks/news-card";
@import "blocks/download";
@import "blocks/contacts";
@import "blocks/columns";
@import "blocks/logos";
@import "blocks/archive";
@import "blocks/error-page";
@import "blocks/article";
@import "blocks/pushka";
@import "blocks/pushka-events";
@import "blocks/culture";
@import "blocks/tip";
@import "blocks/bottom-pane";
@import "blocks/service-msg";
@import "blocks/cookie-msg";
@import "blocks/event-msg";
@import "blocks/modal-gallery";
@import "blocks/rules";
