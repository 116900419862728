.select {
  position: relative;

  select {
    @include text-button;
    color: inherit;
    font-family: inherit;
    text-transform: uppercase;
    width: 100%;
    display: block;
    height: rem(56);
    background-color: transparent;
    border-color: var(--color-border);
    border-left: none;
    border-right: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    @include media("desktop") {
      display: none;
    }
  }

  &:after {
    @include media("mobile") {
      content: "";
      pointer-events: none;
      position: absolute;
      top: rem(18);
      right: rem(6);
      height: rem(13);
      width: rem(13);
      transform: rotate(-45deg) skew(7deg, 7deg);
      border-left: 1px solid var(--color-text-light);
      border-bottom: 1px solid var(--color-text-light);
    }
  }
}

.select-custom {
  position: relative;
  z-index: 1;
  text-transform: uppercase;

  @include media("mobile") {
    display: none;
  }

  button {
    font: inherit;
    text-transform: inherit;
    color: inherit;
    @include text-button;
  }

  &__placeholder {
    position: relative;
    z-index: 1;
    width: 100%;
    display: block;
    height: rem(64);
    padding-left: rem(16);
    padding-right: rem(8);
    background-color: transparent;
    border: 1px solid var(--color-border);
    border-left: none;
    border-right: none;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: rem(24);
      height: rem(24);
      width: rem(30);
      transition: transform 0.4s ease-out;
      color: var(--color-text-light);
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-text-light);

      opacity: 0;
      transition: opacity 0.4s ease-out;
    }

    &:hover:before {
      opacity: 0.1;
    }
  }

  &.active &__placeholder svg {
    transform: rotate(180deg);
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--color-border);

    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-out;
    transition-property: opacity, visibility;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid var(--color-text-light);
      opacity: 0.2;
    }
  }

  &.active &__options {
    opacity: 1;
    visibility: visible;
  }

  &__option {
    @include text-16;
    position: relative;
    z-index: 1;
    padding: rem(21) rem(16);
    background-color: transparent;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover:after,
    &.selected:after {
      opacity: 0.3;
    }

    svg {
      flex: 0 0 auto;
      margin-left: rem(24);
      height: rem(10);
      width: rem(10);
      opacity: 0;
    }

    &.selected svg {
      opacity: 1;
    }

    &:before {
      pointer-events: none;
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid var(--color-text-light);
      opacity: 0.2;
    }

    &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-color: var(--color-text-light);
      transition: opacity 0.4s ease-out;
    }
  }
}
