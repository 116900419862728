.exposition {
  &__header {
    @include media("desktop") {
      margin-top: rem(100);
      padding-bottom: rem(19);
      border-bottom: 1px solid var(--color-border);
      margin-bottom: rem(36);
    }

    @include media("mobile") {
      margin-top: rem(80);
      margin-bottom: rem(60);
    }
  }

  &__title {
    margin-bottom: 0;
  }
}
