.faq {
  @include text-18;

  li {
    &::before {
      font-weight: 500;
    }

    @include media("desktop") {
      margin-bottom: rem(36);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__question {
    margin-bottom: rem(8);

    @include media("desktop") {
      font-weight: 500;
    }

    @include media("mobile") {
      font-weight: 500;
    }
  }

  &__answer {
  }

  &__also {
    @include text-14-16;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(44);
    }

    @include media("mobile") {
      margin-top: rem(24);
      margin-left: rem(-32);
    }
  }
}
