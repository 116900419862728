.picture-modal {
  @include media("desktop") {
    position: relative;
    width: rem(825);
    margin-left: auto;
  }

  @include media("mobile") {
    margin-top: rem(124);
    padding-top: rem(56);
  }

  &__in {
    overflow-y: auto;

    @include media("desktop") {
      max-height: 100vh;
      min-height: 100vh;
      padding-top: rem(60);
      padding-bottom: rem(60);
      padding-left: rem(238);
      padding-right: rem(72);
    }

    @include media("mobile") {
      height: calc(100vh - 7.75rem);
      padding-bottom: rem(60);
      padding-left: rem(16);
      padding-right: rem(16);
      padding-top: rem(140);
    }
  }

  &__close {
    position: absolute;
    width: rem(24);

    @include media("desktop") {
      top: rem(24);
      right: rem(24);
    }

    @include media("mobile") {
      top: rem(84);
      right: rem(16);
      color: var(--color-border);
    }
  }

  &__img {
    position: absolute;
    pointer-events: none;

    img {
      opacity: 0;
      transition: 0.2s ease-in-out;

      &.loaded {
        opacity: 1;
      }
    }

    @include media("desktop") {
      left: 0;
      width: rem(386);
      transform: translate(-50%);
      top: rem(60);

      img {
        object-fit: contain;
        max-height: calc(100vh - 7.5rem);
      }
    }

    @include media("mobile") {
      top: rem(84);
      left: rem(16);
      height: rem(212);
      transition: transform 0.4s ease-out;
      transform-origin: 0% 0%;

      img {
        width: auto;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      &.small {
        // height: rem(76);
        transform: scale(0.36);
      }
    }
  }

  &__info-tags {
    @include text-button;
    color: var(--color-text-light);

    @include media("desktop") {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: rem(40);
    }

    @include media("mobile") {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(4);
      padding-bottom: rem(24);
      margin-bottom: rem(24);
      border-bottom: 1px solid var(--color-border);
    }
  }

  &__info-tag {
    @include media("desktop") {
      border-right: 1px solid var(--color-border);
      padding-right: rem(16);
      margin-right: rem(16);

      &:last-child {
        border-right: none;
        padding-right: 0;
        margin-right: unset;
      }
    }
  }

  &__title {
    @include h2;
    margin-bottom: rem(24);
  }

  &__text {
    @include media("desktop") {
      padding-bottom: rem(60);
    }

    @include media("mobile") {
      padding-bottom: rem(100);
    }

    h1 {
      @include h3;
      margin-bottom: rem(16);
      margin-top: rem(40);
    }
  }
}
