.gallery-slider {
  position: relative;
  overflow: hidden;

  @include media("desktop") {
    padding-top: rem(48);
    padding-bottom: rem(28);
    margin-top: rem(80);
    margin-bottom: rem(80);
  }

  @include media("mobile") {
    padding-top: rem(40);
    margin-top: rem(56);
    margin-bottom: rem(56);
  }

  &__container {
    position: relative;
  }

  &__title {
    @include media("desktop") {
      margin-bottom: rem(80);
      width: rem(580);
    }
  }

  &__slider {
    overflow: visible;
    margin-left: rem(-20);
    margin-right: rem(-20);

    @include media("desktop") {
      margin-bottom: rem(32);
    }

    @include media("mobile") {
      margin-bottom: rem(40);
    }
  }

  &__slider-wrapper {
    @include media("desktop") {
      align-items: stretch;
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding-left: rem(20);
    padding-right: rem(20);
    border-left: 1px solid var(--color-border);
    height: auto;

    &:first-child {
      border-left: none;
    }

    @include media("desktop") {
      width: rem(320);

      &:nth-child(n + 5) {
        opacity: 0;
        visibility: hidden;
      }
    }

    @include media("mobile") {
      width: rem(257);
    }
  }

  &__navigation {
    @include media("desktop") {
      position: absolute;
      top: rem(10);
      right: 0;
    }

    @include media("mobile") {
      display: none;
    }
  }
}
