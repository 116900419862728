@mixin color-creme-text {
  --color-text: #{$color-creme-text};
}

@mixin color-creme-text-light {
  --color-text-light: #{$color-creme-text-light};
}

@mixin color-creme-bg {
  --color-bg: #{$color-creme-bg};
}

@mixin color-creme-border {
  --color-border: #{$color-creme-border};
}

@mixin color-creme-accent {
  --color-accent: #{$color-creme-accent};
}

@mixin theme-creme {
  @include color-creme-text;
  @include color-creme-text-light;
  @include color-creme-bg;
  @include color-creme-border;
  @include color-creme-accent;
}

@mixin color-blue-text {
  --color-text: #{$color-blue-text};
}

@mixin color-blue-text-light {
  --color-text-light: #{$color-blue-text-light};
}

@mixin color-blue-bg {
  --color-bg: #{$color-blue-bg};
}

@mixin color-blue-border {
  --color-border: #{$color-blue-border};
}

@mixin color-blue-accent {
  --color-accent: #{$color-blue-accent};
}

@mixin theme-blue {
  @include color-blue-text;
  @include color-blue-text-light;
  @include color-blue-bg;
  @include color-blue-border;
  @include color-blue-accent;
}

@mixin color-push-text {
  --color-text: #{$color-push-text};
}

@mixin color-push-text-light {
  --color-text-light: #{$color-push-text-light};
}

@mixin color-push-bg {
  --color-bg: #{$color-push-bg};
}

@mixin color-push-border {
  --color-border: #{$color-push-border};
}

@mixin color-push-accent {
  --color-accent: #{$color-push-accent};
}

@mixin theme-push {
  @include color-push-text;
  @include color-push-text-light;
  @include color-push-bg;
  @include color-push-border;
  @include color-push-accent;
}

@mixin color-base-text {
  --color-text: #{$color-base-text};
}

@mixin color-base-text-light {
  --color-text-light: #{$color-base-text-light};
}

@mixin color-base-bg {
  --color-bg: #{$color-base-bg};
}

@mixin color-base-border {
  --color-border: #{$color-base-border};
}

@mixin color-base-accent {
  --color-accent: #{$color-base-accent};
}

@mixin theme-base {
  @include color-base-text;
  @include color-base-text-light;
  @include color-base-bg;
  @include color-base-border;
  @include color-base-accent;
}

@mixin text-creme {
  @include color-creme-text;
  color: var(--color-text);
}

@mixin text-blue {
  @include color-blue-text;
  color: var(--color-text);
}

@mixin text-push {
  @include color-push-text;
  color: var(--color-text);
}

@mixin text-base {
  @include color-base-text;
  color: var(--color-text);
}

@mixin bg-creme {
  @include color-creme-bg;
  background-color: var(--color-bg);
}

@mixin bg-blue {
  @include color-blue-bg;
  background-color: var(--color-bg);
}

@mixin bg-push {
  @include color-push-bg;
  background-color: var(--color-bg);
}

@mixin bg-base {
  @include color-base-bg;
  background-color: var(--color-bg);
}
