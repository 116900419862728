.download {
  &__list {
    .article-nav__section + & {
      @include media("desktop") {
        margin-top: rem(-36);
      }

      @include media("mobile") {
        margin-top: rem(-24);
      }
    }
  }

  &__link {
    border-bottom: 1px solid var(--color-border);
    padding-top: rem(20);
    padding-bottom: rem(20);

    @include text-18-desktop;
    @include text-16-mobile;

    display: flex;
    justify-content: space-between;

    transition: opacity 0.4s ease-out;

    &:hover {
      opacity: 0.5;
    }

    @include media("desktop") {
      align-items: center;
    }

    @include media("mobile") {
      align-items: flex-start;
    }
  }

  &__link-img {
    @include text-button;
    display: flex;
    color: var(--color-text-light);
    margin-left: rem(24);
    flex: 0 0 auto;

    svg {
      height: rem(24);
      width: auto;
      margin-right: rem(8);
    }
  }
}
