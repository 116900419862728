.inset-3 {
  &__container {
  }

  &__slider {
    z-index: 1;

    @include media("desktop") {
      margin-top: rem(129);
      margin-bottom: rem(-52);
    }

    @include media("mobile") {
      position: relative;
      margin-top: rem(80);
      margin-bottom: rem(56);
    }
  }

  &__slide {
    @include media("desktop") {
      display: flex;
      padding-left: rem(60);
      padding-right: rem(60);
    }
  }

  &__img {
    @include media("desktop") {
      @include img-fit(540, 540);
      flex: 0 0 auto;
      width: rem(540);
      margin-right: rem(80);
    }

    @include media("mobile") {
      @include img-fit(328, 204);
      margin-bottom: rem(68);
    }
  }

  &__main {
    @include media("desktop") {
      padding-top: rem(64);
      padding-bottom: rem(188);
    }
  }

  &__controls {
    position: absolute;
    z-index: 1;

    @include media("desktop") {
      left: rem(680);
      bottom: rem(100);
    }

    @include media("mobile") {
      top: rem(224);
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__navigation {
  }

  &__pagination {
    @include media("desktop") {
      display: none;
    }

    @include media("mobile") {
    }
  }
}
