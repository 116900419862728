.team {
  @include media("desktop") {
    padding-top: rem(48);
    padding-bottom: rem(32);
  }

  @include media("mobile") {
    padding-top: rem(40);
    padding-bottom: rem(68);
  }

  &__header {
    border-bottom: 1px solid var(--color-border);
    margin-bottom: rem(24);
    padding-bottom: rem(20);
  }

  &__header-title {
    @include text-button;
    margin: 0;
  }

  &__items {
    @include media("desktop") {
      display: flex;
      align-items: stretch;
      margin-left: rem(-20);
      margin-right: rem(-20);
    }

    @include media("mobile") {
    }
  }

  &__item {
    @include media("desktop") {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: rem(378);
      padding-left: rem(20);
      padding-right: rem(20);
      width: 100%;

      &:nth-child(n + 2) {
        border-left: 1px solid var(--color-border);
      }
    }

    @include media("mobile") {
      margin-bottom: rem(32);
      display: grid;
      grid-template-areas:
        "img name"
        "img description"
        "img .";
      gap: rem(8) rem(16);
    }
  }

  &__item-img {
    margin-bottom: auto;

    @include media("mobile") {
      grid-area: img;
      width: rem(122);
      @include img-fit(122, 140);
    }
  }

  &__item-name {
    @include text-button;
    @include media("desktop") {
      margin-top: rem(24);
    }

    @include media("mobile") {
      grid-area: name;
    }
  }

  &__item-description {
    @include text-14-16;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(8);
    }

    @include media("mobile") {
      grid-area: description;
    }
  }
}
