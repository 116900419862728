.article-mobile-nav {
  @include media("desktop") {
    display: none;
  }

  @include media("mobile") {
    @include text-button;
    position: fixed;
    z-index: 8;
    top: rem(44);
    left: 0;
    width: 100%;

    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);

    transition: 0.4s ease-out;
    transition-property: opacity, visibility, transform;

    .body--article-nav-mobile-visible & {
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &__placeholder {
    @include text-button;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(9) rem(16);
    height: rem(44);
    background-color: var(--color-border);
    transition: background-color 0.4s ease-out;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      pointer-events: none;
      top: 0;
      left: rem(16);
      right: rem(16);
      width: auto;
      border-top: 1px solid var(--color-border);
    }

    svg {
      height: rem(24);
      width: rem(24);
      flex: 0 0 auto;
      margin-left: rem(24);
      transition: transform 0.4s ease-out;
    }

    .text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.open &__placeholder {
    background-color: var(--color-bg);

    svg {
      transform: rotate(180deg);
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--color-border);

    height: calc(100vh - rem(88));
    overflow: auto;

    opacity: 0;
    visibility: hidden;

    transition: 0.4s ease-out;
    transition-property: opacity, visibility;
  }

  &.open &__list {
    opacity: 1;
    visibility: visible;
  }

  &__link {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: rem(16);

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-text-light);
      opacity: 0;
      transition: opacity 0.2 ease;
    }

    &.active:before {
      opacity: 0.2;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid var(--color-text-light);
      opacity: 0.2;
    }
  }
}
