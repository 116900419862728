.pushkin {
  @include media("desktop") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__info {
    @include media("desktop") {
      width: rem(386);
    }
  }

  &__logo {
    svg {
      width: rem(124);
    }

    @include media("mobile") {
      margin-top: rem(24);
    }
  }

  &__btn {
    width: 100%;
  }
}
