.address {
  @include media("mobile") {
    margin-top: rem(24);
    margin-bottom: rem(40);
  }

  &__row {
    @include text-16;

    @include media("desktop") {
      & + & {
        margin-top: rem(32);
      }
    }

    @include media("mobile") {
      & + & {
        margin-top: rem(24);
      }

      &:first-child {
        padding-top: rem(16);
        border-top: 1px solid var(--color-border);
      }

      &:last-child {
        padding-bottom: rem(16);
        border-bottom: 1px solid var(--color-border);
      }
    }
  }

  &__title {
    @include text-button;
    text-transform: uppercase;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-bottom: rem(8);
    }

    @include media("mobile") {
      margin-bottom: rem(4);
    }
  }

  &__text {
    @include media("desktop") {
      max-width: rem(370);
    }
  }

  &__text-part {
    @include media("desktop") {
      display: inline-block;
      margin-right: rem(4);
      padding-right: rem(8);
      border-right: 1px solid var(--color-border);

      &:last-child {
        border: none;
      }
    }
  }

  &__btn {
  }

  &__row + &__btn {
    @include media("desktop") {
      margin-top: rem(40);
    }

    @include media("mobile") {
      margin-top: rem(24);
    }
  }
}
