.rules {
  @include media("desktop") {
    margin-bottom: rem(136);
  }

  @include media("mobile") {
    margin-bottom: rem(60);
  }

  &__header {
    @include media("desktop") {
      width: rem(734);
      margin-left: auto;
      margin-right: auto;
      padding-top: rem(100);
      padding-bottom: rem(18);
      margin-bottom: rem(36);
      border-bottom: 1px solid var(--color-border);
    }

    @include media("mobile") {
      padding-top: rem(80);
      border-bottom: 1px solid var(--color-border);
      padding-bottom: rem(24);
      margin-bottom: rem(24);
    }
  }

  &__type {
    @include text-16;

    @include media("desktop") {
      margin-bottom: rem(12);
    }

    @include media("mobile") {
      margin-bottom: rem(10);
    }
  }

  &__title {
    margin: 0;
    @include media("desktop") {
    }
  }
}
