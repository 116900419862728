.archive {
  .article-nav__section + & {
    @include media("desktop") {
      margin-top: rem(-36);
    }

    @include media("mobile") {
      margin-top: rem(-24);
    }
  }

  &__item {
    border-bottom: 1px solid var(--color-border);

    &:last-child {
      border-bottom: none;
    }

    @include media("desktop") {
      padding-top: rem(36);
      padding-bottom: rem(36);
    }

    @include media("mobile") {
      padding-top: rem(24);
      padding-bottom: rem(24);
    }
  }

  &__item-media {
    position: relative;
    display: block;
    margin-bottom: auto;
    flex: 0 0 auto;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include media("desktop") {
        height: rem(64);
        width: rem(64);
      }

      @include media("mobile") {
        height: rem(48);
        width: rem(48);
      }
    }
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  &__item-date {
    color: var(--color-text-light);
  }

  &__item-title {
    @include h3;
    margin-top: rem(8);
    margin-bottom: auto;
  }

  &__item-file {
    @include text-button;
    color: var(--color-text-light);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media("desktop") {
      margin-top: rem(36);
      transition: opacity 0.4s ease-out;
      opacity: 1;

      &:hover {
        opacity: 0.5;
      }
    }

    @include media("mobile") {
      margin-top: rem(16);
    }

    svg {
      width: rem(24);
      margin-right: rem(8);
    }
  }

  &__item--zip {
    display: flex;

    @include media("desktop") {
      gap: rem(20);
    }

    @include media("mobile") {
      gap: rem(16);
    }
  }

  &__item--zip &__item-media {
    @include media("desktop") {
      width: rem(120);
    }

    @include media("mobile") {
      width: rem(56);
    }
  }

  &__item--video &__item-media {
    @include media("desktop") {
      margin-bottom: rem(20);
      @include img-fit(813, 450);
    }

    @include media("mobile") {
      margin-bottom: rem(16);
      @include img-fit(328, 184);
    }
  }
}
