.modal {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;

  opacity: 0;
  visibility: hidden;
  transition: 0.2 ease;
  transition-property: opacity, visibility;

  @include media("mobile") {
    height: 100vh;
  }

  &--show {
    visibility: visible;
    opacity: 1;
  }

  &__overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25%);
    background: $color-black;

    &--show {
      animation: showOverlayLight 0.3s ease forwards;

      body.menu-open.theme-push &,
      body.menu-open.theme-blue & {
        animation: showOverlayDark 0.3s ease forwards;
      }
    }
  }

  &__content {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  &__overlay-in {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__size {
    position: relative;
    min-height: 100%;
    width: 100%;
    z-index: 1;
  }

  &__overflow {
    overflow: auto;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
  }
  &--loading &__loader {
    display: flex;
  }
}

@keyframes showOverlayLight {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 0.2;
  }
}

@keyframes showOverlayDark {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 0.3;
  }
}
