.event-card {
  display: grid;

  @include media("desktop") {
    grid-template-areas:
      "img type"
      "img title"
      "img ."
      "img datetime";
    grid-template-columns: 1fr 10fr;
    height: auto;
    grid-template-rows: min-content min-content auto min-content;

    * {
      transition: opacity 0.4s ease-out;
    }

    &:hover > * {
      opacity: 0.5;
    }
  }

  @include media("mobile") {
    width: 100% !important;
    grid-template-areas:
      "type type type"
      "title title title"
      ". . img"
      "datetime . img";
  }

  &__img {
    grid-area: img;

    @include media("desktop") {
      @include img-fit(184, 238);
      width: rem(184);
      margin-right: rem(20);
    }

    @include media("mobile") {
      @include img-fit(100, 120);
      width: rem(100);
      margin-left: auto;
    }
  }

  &__type {
    grid-area: type;
    @include text-16;
    color: var(--color-text-light);
  }

  &__title {
    grid-area: title;
    @include h2;
    margin-top: rem(8);

    @include media("desktop") {
      margin-bottom: auto;
    }

    @include media("mobile") {
      margin-bottom: rem(16);
    }
  }

  &__datetime {
    grid-area: datetime;
    margin-top: auto;
    color: var(--color-text-light);

    @include media("desktop") {
      margin-top: rem(16);
    }
  }

  &__date {
    @include h2-mobile;
    @include h3-desktop;
  }

  &__time {
    @include text-16;
    margin-top: rem(4);
  }
}
