.future {
  position: relative;
  overflow: hidden;

  @include media("desktop") {
    margin-top: rem(56);
  }

  @include media("mobile") {
    margin-top: rem(36);
  }

  &__ticker {
    @include text-creme;
    display: flex;
    justify-content: center;

    @include media("desktop") {
      margin-bottom: rem(148);
    }

    @include media("mobile") {
      margin-bottom: rem(162);
    }
  }

  &__ticker-item {
    @include h2;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    white-space: nowrap;

    animation: ticker 10s linear infinite;

    &:after {
      content: "";
      display: block;
      height: rem(4);
      width: rem(4);
      border-radius: 50%;
      background-color: var(--color-text);

      @include media("desktop") {
        margin-left: rem(20);
        margin-right: rem(20);
      }

      @include media("mobile") {
        margin-left: rem(16);
        margin-right: rem(16);
      }
    }
  }

  &__pane-container {
    position: relative;
    padding-top: 1px;

    @include media("desktop") {
      width: rem(932);
    }

    @include media("mobile") {
      padding-bottom: rem(32);
    }
  }

  &__pane-img {
    @include media("desktop") {
      margin-top: rem(-104);
    }

    @include media("mobile") {
      margin-top: rem(-122);
      margin-bottom: rem(32);
    }
  }

  &__pane-figure-1,
  &__pane-figure-2 {
    color: var(--color-border);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("desktop") {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto rem(24);
      height: rem(248);
      width: rem(517);
    }

    @include media("mobile") {
      width: rem(328);
      height: rem(158);
    }
  }

  &__pane-figure-1 {
    @include media("desktop") {
      left: 100%;
    }

    @include media("mobile") {
      display: none;
    }
  }

  &__pane-figure-2 {
    @include media("desktop") {
      right: 100%;
    }

    @include media("mobile") {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@keyframes ticker {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translate(-100%);
  }
}
