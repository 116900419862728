.inset-2 {
  @include media("desktop") {
    padding-top: rem(120);
    padding-bottom: rem(120);
  }

  @include media("mobile") {
    padding-top: rem(40);
    padding-bottom: rem(100);
  }

  &__container {
    @include media("desktop") {
      display: flex;
      flex-direction: row-reverse;
      align-items: stretch;
    }
  }

  &__aside {
    @include media("desktop") {
      flex: 1 1 auto;
      margin-left: rem(60);
      margin-right: rem(40);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      width: auto;
    }
  }

  &__main {
    @include h2;
    @include media("desktop") {
      flex: 0 0 auto;
      width: rem(728);
    }

    @include media("mobile") {
      margin-bottom: rem(24);
    }
  }

  &__btn {
    @include media("mobile") {
      margin-top: rem(36);
    }
  }
}
